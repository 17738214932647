import './App.css';
import logo from './logo.svg';

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
          <img className={"App-logo"} src={logo} alt={"insectfeet spotlight logo"} />
          <div className={"title"}>insectfeet.com</div>
          <div className={"container"}>
            <div className={"services"}>
              <ul className={"horiz-list"} id={"av-services"}>
                <li>video</li>
                <li>livestreaming</li>
                <li>audio</li>
                <li>installations</li>
              </ul>
            </div>
            <hr />
            <div className={"portfolio"}>
              <ul className={"horiz-list"}>
                <li>examples: <a href={"https://youtube.com/playlist?list=PLj4f2hM3vN1tgQHvcYVqDaWDjCDqr1ldr&si=tZx7JzngjDNfXXvG"}>Machina Bristronica 2023 - YouTube</a></li>
              </ul>
            </div>
            <hr />
          </div>
          <div className={"contact"}>get in touch:<br /><a href={"mailto:contact@insectfeet.com"}>contact@insectfeet.com</a></div>
      </header>
    </div>
  );
}

export default App;
